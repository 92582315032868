<template>
  <div style="margin: 10px">
    <div class="row">
      <div class="col-12 mb-3 mt-3">
        <div class="alert alert-secondary" role="alert">
          {{ descripcionHechos }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <label for="Quepaso" class="form-label"> ¿Qué sucedió? </label>
        <textarea
          class="form-control"
          :class="{ error: $v.form.quePaso.$error }"
          id="Quepaso"
          v-model="$v.form.quePaso.$model"
          rows="3"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <label for="ComoSucedio" class="form-label"> ¿Cómo sucedió? </label>
        <textarea
          class="form-control"
          id="ComoSucedio"
          :class="{ error: $v.form.comoPaso.$error }"
          v-model="$v.form.comoPaso.$model"
          rows="3"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <label for="DondeSucedio" class="form-label"> ¿Dónde sucedió? </label>
        <textarea
          class="form-control"
          id="DondeSucedio"
          :class="{ error: $v.form.dondePaso.$error }"
          v-model="$v.form.dondePaso.$model"
          rows="3"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <label for="CuandoSucedio" class="form-label"> ¿Cuándo sucedió? </label>
        <textarea
          class="form-control"
          id="CuandoSucedio"
          :class="{ error: $v.form.cuandoPaso.$error }"
          v-model="$v.form.cuandoPaso.$model"
          rows="3"
        ></textarea>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "Complement",
  props: ["clickedNext", "currentStep"],
  activated: function () {
    /* console.log("activate"); */
    if (!this.$v.form.$invalid) {
      // console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  deactivated: function () {
    // console.log("deactivated");
  },
  data() {
    return {
      form: {
        quePaso: "",
        comoPaso: "",
        cuandoPaso: "",
        dondePaso: "",
      },
    };
  },
  computed:{
    ...mapGetters('generals', ['descripcionHechos']),
  },
  methods: {
    ...mapMutations("contenido", ["setHechos"]),
  },
  validations: {
    form: {
      quePaso: {
        required,
      },
      comoPaso: {
        required,
      },
      cuandoPaso: {
        required,
      },
      dondePaso: {
        required,
      },
    },
  },
  watch: {
    $v: {
      handler: function (val) {
        // console.log("es invalido cambio.... ", val.$invalid);
        if (!val.$invalid) {
          this.$emit("can-continue", { value: true });
          this.setHechos(this.form);
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
      }
    },
  },
};
</script>
<style scoped>
.alert-secondary {
  color: #e2e3e5;
  background-color: #1c1c38;
  border-color: #1c1c38;
}
.error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>