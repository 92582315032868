<template>
  <div style="margin: 10px">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="alert alert-secondary" role="alert">
          Describe los agravios que consideras relevantes. Usa las herramientas para darle formato a tu texto, y así trasmitir
          mejor tus ideas.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <Editor tipoMedidas="descripcionAgravios"></Editor>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from "../../Editor/Editor";
import { mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";


export default {
  name: "Agravios",
  components: {
    Editor,
  },
  data() {
    return {
    };
  },
  activated: function () {
    /* console.log("activate"); */
    if (
      this.descripcionAgravios != "<p></p>" &&
      this.descripcionAgravios != "<h3></h3>" &&
      this.descripcionAgravios != ""
    ) {
      console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  computed: {
    ...mapState("contenido", ["descripcionAgravios"]),
  },
  methods: {
    ...mapMutations("contenido", ["setFormatoDatos"]),
  },
  validations: {
    form: {
      instituto: {
        required,
      },
    },
  },
  watch: {
    descripcionAgravios(val) {
      // console.log(val);
      if (
        val != "<p></p>" &&
        val != "<h3></h3>" &&
        val != ""
      ) {
        this.$emit("can-continue", { value: true });
        // console.log("can continue medidasProteccion watch");
      } else {
        this.$emit("can-continue", { value: false });
      }
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
        // this.setFormatoDatos(this.form)
      }
    },
  },
};
</script>
<style scoped>
.alert-secondary {
  color: #e2e3e5;
  background-color: #1c1c38;
  border-color: #1c1c38;
}
.selectMargin {
  margin-bottom: 200px;
}

.error {
  border: 1px solid #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
</style>